import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Constants from '../../../shared/constants';
import { MainContext } from '../../../components/Toast/Toast';
import Header from '../../../components/Header/Header';
import GroupsService from '../../../services/AdminServices/GroupsService';
import Config from '../../../config';
import {
  FlexContainer,
  Container, Wrapper,
  OptionButton,
  TableHeader,
  TableValue,
  TableData,
  Border,
} from '../users/styles';
import {
  PageName, CurrentPage, WhiteCard, DetailsLeft,
} from '../../gigger/styles';
import { Line, GroupDetailsWrapper } from '../giggers/styles';
import { Card, Span, Div } from '../../../components/Popover/Style';
import { WhiteButton } from '../../customer/styles';
import DeleteGroupModal from './DeleteGroupModal';
import Modal from './Modal';
import GiggerService from '../../../services/AdminServices/GiggersService';
import LoadingCard from '../users/LoadingCard';
import GroupFilter from './GroupFilter';
import AddGiggersToGroupModal from './AddGiggersToGroupModal';
import RenameGroupModal from './RenameGroupModal';
import DeleteMemberModal from './DeleteMemberModal';

const Details = ({ match }) => {
  const groupsService = useRef(new GroupsService(Config.apiBase));
  const giggerService = useRef(new GiggerService(Config.apiBase));
  const history = useHistory();
  const { params: { id = '' } = {} } = match;
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState({});
  const [groupData, setGroupData] = useState({});
  const [openColumn, setOpenColumn] = useState('');
  const [userData, setUserData] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddGiggersModalOpen, setIsAddGiggersModalOpen] = useState(false);
  const [isRenameGroupModalOpen, setIsRenameGroupModalOpen] = useState(false);
  const [isDeleteMemberModalOpen, setIsDeleteMemberModalOpen] = useState(false);
  const [giggerList, setGiggersList] = useState([]);
  const [updateGiggerList, setUpdateGiggerList] = useState(true);
  const [giggersData, setGiggersData] = useState([]);
  const filterOptions = useRef([]);
  const openElement = useRef(null);

  const { users = [] } = groupData || {};

  useEffect(() => {
    if (isAddGiggersModalOpen || isDeleteModalOpen
      || isRenameGroupModalOpen || isDeleteMemberModalOpen) {
      openElement.current.click();
    }
  }, [isAddGiggersModalOpen, isDeleteModalOpen, isRenameGroupModalOpen, isDeleteMemberModalOpen]);

  const fetchGroupDetails = async (params = []) => {
    setLoading(true);
    try {
      const response = await groupsService.current.getGroupDetailsById(id, params);
      if (response) {
        setGroupData(response?.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      // catch error
    }
  };

  const fetchGiggers = async () => {
    const queryParams = [
      ['export', true],
    ];
    setLoading(true);
    try {
      const response = await giggerService.current.getGiggerList(queryParams);
      const { data: giggers } = response;

      setGiggersData(giggers?.map((o) => {
        const { full_name: fullName, id: giggerId } = o;
        return ({ label: fullName, value: giggerId });
      }));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (giggersData?.length && updateGiggerList) {
      const filteredUsers = users?.map((o) => o?.id);
      const filteredGiggers = filteredUsers?.length
        ? giggersData.filter((o) => !filteredUsers.includes(o?.value)) : giggersData;
      setGiggersList(filteredGiggers);
      setUpdateGiggerList(false);
    }
  }, [users, giggersData]);

  useEffect(() => {
    fetchGroupDetails();
    fetchGiggers();
  }, []);

  const handleToast = (values) => {
    setToastData(values);
    setShowToast(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push(Constants.routes.logIn.url);
  };

  const handleLogintoapp = () => {
    history.push(Constants.routes.adminMyAccount.url);
  };

  const handleGroupPage = () => {
    history.push(Constants.routes.groups.url);
  };

  const toFocusOnDiv = () => {
    document.getElementById('clickable-component').focus();
  };

  const handleUpdateGroupDetails = async (payload) => {
    setLoading(true);
    try {
      const response = await groupsService.current.updateGroupDetails(id, payload);
      if (response) {
        fetchGroupDetails();
        setUpdateGiggerList(true);
        setLoading(false);
        handleToast({ type: 'success', message: 'Group details updated' });
      }
    } catch (e) {
      setLoading(false);
      handleToast({ type: 'error', message: 'Failed to update group' });
    }
  };

  const handleAddGiggersToGroup = () => {
    if (isAddGiggersModalOpen) {
      fetchGroupDetails();
    }
    setIsAddGiggersModalOpen(!isAddGiggersModalOpen);
  };

  const handleDeleteUser = () => {
    const filteredUsers = users.filter((o) => o?.id !== userData?.id);
    const payload = {
      name: groupData?.name,
      gigger_id: filteredUsers.map((o) => (o?.id)),
    };
    handleUpdateGroupDetails(payload);
  };

  const redirectToUserDetails = () => {
    history.push(`/admin/portal/gigger/${userData?.id}/profile`);
  };

  const handleDisplayOptions = () => {
    setOpenColumn('');
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        filterOptions.current[openColumn]
          && !filterOptions.current[openColumn].contains(e.target)
      ) {
        setOpenColumn('');
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [openColumn]);

  const handleOptions = (index, userdata) => {
    setOpenColumn((prev) => {
      const isSameColumn = prev === index;
      if (!isSameColumn) {
        if (filterOptions?.current?.[index]) {
          filterOptions.current[index].focus();
        }
      }
      return isSameColumn ? '' : index;
    });
    setUserData(userdata);
  };

  const handleDeleteGroup = () => {
    if (isDeleteModalOpen) {
      fetchGroupDetails();
    }
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleRenameGroup = () => {
    setIsRenameGroupModalOpen(!isRenameGroupModalOpen);
  };

  const handleDeleteMember = () => {
    setIsDeleteMemberModalOpen(!isDeleteMemberModalOpen);
  };

  const handleUpdateGiggers = (giggers) => {
    const giggerIds = giggers?.value?.split(',');
    const userIds = users.map((o) => (o?.id));
    const payload = {
      name: groupData?.name,
      gigger_id: [...userIds, ...giggerIds],
    };
    handleUpdateGroupDetails(payload);
  };

  const handleUpdateGroupName = (name) => {
    const payload = {
      name,
      gigger_id: users.map((o) => (o?.id)),
    };
    handleUpdateGroupDetails(payload);
  };

  return (
    <MainContext.Provider value={{ showToast, toastData, onToastClose: () => setShowToast(false) }}>
      <Header
        name={groupData?.name}
        handleLogintoapp={handleLogintoapp}
        handleLogout={handleLogout}
        groupDetails
        handleAddMembersToGroup={handleAddGiggersToGroup}
        handleDeleteGroup={handleDeleteGroup}
        handleRenameGroup={handleRenameGroup}
      />
      <FlexContainer>
        <Container>
          <Wrapper className="mb-3">
            <PageName className="opacity-7 " onClick={handleGroupPage} role="button">
              <FormattedMessage id="groups" defaultMessage="Groups" />
              {'  >  '}
            </PageName>
            <span>&nbsp;</span>
            <CurrentPage>{groupData?.name}</CurrentPage>
          </Wrapper>
          <div className="d-flex">
            <GroupDetailsWrapper minWidth="70%">
              {loading ? (
                <GroupDetailsWrapper>
                  <LoadingCard />
                </GroupDetailsWrapper>
              ) : (
                <>
                  <Wrapper className="row m-0">
                    <TableData className="col">
                      <TableHeader>
                        <FormattedMessage id="label_members" defaultMessage="Members" />
                      </TableHeader>
                    </TableData>

                    <TableData className="col">
                      <TableHeader>
                        <FormattedMessage id="label_salary" defaultMessage="Salary" />
                      </TableHeader>
                    </TableData>

                    <TableData className="col-auto">
                      <OptionButton />
                    </TableData>
                  </Wrapper>
                  {users.length ? (
                    <>
                      {users.map((obj, index) => {
                        const { full_name: fullName = '', accounts: { hourly_rate: hourlyRate = '' } = {} } = obj;
                        return (
                          <>
                            <Border />
                            <Wrapper className="row m-0">
                              <TableData className="col text-truncate" title={obj.name}>
                                <Link
                                  to={{
                                    pathname: `/admin/portal/gigger/${obj.id}/profile`,
                                    query: { id: users.id },
                                  }}
                                >
                                  <TableValue>{fullName ? <Line>{fullName}</Line> : '-'}</TableValue>
                                </Link>
                              </TableData>
                              <TableData className="col">
                                <TableValue>{hourlyRate ? `${hourlyRate} SEK/h` : '-'}</TableValue>
                              </TableData>
                              <TableData
                                className="col-auto"
                                ref={(el) => {
                                  if (el) filterOptions.current[index] = el;
                                }}
                              >
                                <TableValue>
                                  <WhiteButton onClick={() => handleOptions(index, obj)}>
                                    <img src={Constants.icons.ThreeDots} alt="icon" className="cursor-pointer" />
                                  </WhiteButton>
                                  {openColumn === index && (
                                    <Card
                                      onBlur={handleDisplayOptions}
                                      tabIndex="-1"
                                      id="clickable-component"
                                      onLoad={toFocusOnDiv}
                                    >
                                      <>
                                        <Div onClick={redirectToUserDetails} role="button">
                                          <Span>
                                            <FormattedMessage id="common_details" defaultMessage="Details" />
                                          </Span>
                                        </Div>
                                        <Border />
                                        <Div role="button" onClick={handleDeleteMember}>
                                          <Span>
                                            <FormattedMessage id="common_delete" defaultMessage="Delete" />
                                          </Span>
                                        </Div>
                                      </>
                                    </Card>
                                  )}
                                </TableValue>
                              </TableData>
                            </Wrapper>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <Border />
                      <WhiteCard className="my-3">
                        <FormattedMessage id="common_users_found" defaultMessage="No users found" />
                      </WhiteCard>
                    </>
                  )}
                </>
              )}
            </GroupDetailsWrapper>
            <DetailsLeft className="ml-4 pl-2">
              <GroupFilter fetchGroupDetails={fetchGroupDetails} />
            </DetailsLeft>
          </div>
          <a
            href
            data-toggle="modal"
            data-target="#GroupModal"
            data-backdrop="static"
            data-keyboard="false"
            ref={openElement}
          />
          {isDeleteModalOpen && (
            <Modal onModalClose={handleDeleteGroup} header={Constants.language.delete_group}>
              <DeleteGroupModal
                data={groupData}
                handleToast={handleToast}
                onModalClose={handleDeleteGroup}
                setLoading={setLoading}
                isInDetailPage
              />
            </Modal>
          )}
          {isAddGiggersModalOpen && (
            <Modal
              onModalClose={handleAddGiggersToGroup}
              header={Constants.language.add_member_to_group}
            >
              <AddGiggersToGroupModal
                handleOnSubmit={handleUpdateGiggers}
                onModalClose={handleAddGiggersToGroup}
                giggerList={giggerList}
              />
            </Modal>
          )}
          {isRenameGroupModalOpen && (
            <Modal
              onModalClose={handleRenameGroup}
              header={Constants.language.rename_the_group}
            >
              <RenameGroupModal
                handleOnSubmit={handleUpdateGroupName}
                onModalClose={handleRenameGroup}
                name={groupData?.name}
              />
            </Modal>
          )}
          {isDeleteMemberModalOpen && (
            <Modal
              onModalClose={handleDeleteMember}
              header={Constants.language.delete_member}
            >
              <DeleteMemberModal
                handleOnSubmit={handleDeleteUser}
                onModalClose={handleDeleteMember}
              />
            </Modal>
          )}
        </Container>
      </FlexContainer>
    </MainContext.Provider>
  );
};

export default Details;
